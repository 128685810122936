$font1: "Gotham Book";
$font2: "Gotham Medium";
$font3: Consolas;

$border-color: #1B282D;

$color-white: #E4E4E4;
$color-yellow: #FFCE4A;
$color-yellow-dark: #AB8621;
$color-green: #04D939;
$color-green-dark: #0BA130;
$color-red: #D9042B;
$color-red-dark: #BB0325;
$color-grey-dark: #031016;
$color-grey-light: #A4A4A4;
$color-blue-dark: #061b25;
$color-blue-dark2: #182328;
$color-blue-light: #3CF;
