.ps-container {
    -ms-touch-action: none;
    overflow: hidden!important
}

.ps-container.ps-active-y>.ps-scrollbar-y-rail {
    display: block
}

.ps-container.ps-in-scrolling {
    pointer-events: none
}

.ps-container.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail {
    background-color: rgba(0, 0, 0, .3);
    opacity: 1
}

.ps-container.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail>.ps-scrollbar-y {
    background-color: #d9042b
}

.ps-container>.ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    opacity: 0;
    background-color: rgba(0, 0, 0, .1);
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    width: 6px;
    z-index: 10
}

.ps-container>.ps-scrollbar-y-rail>.ps-scrollbar-y {
    position: absolute;
    background-color: #343438;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
    right: 0;
    width: 6px
}

.ps-container:hover.ps-in-scrolling {
    pointer-events: none
}

.ps-container:hover.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail {
    background-color: rgba(0, 0, 0, .3);
    opacity: 1
}

.ps-container:hover.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail>.ps-scrollbar-y {
    background-color: #d9042b
}

.ps-container:hover>.ps-scrollbar-y-rail {
    opacity: 1
}

.ps-container:hover>.ps-scrollbar-y-rail:hover {
    background-color: rgba(0, 0, 0, .3);
    opacity: 1
}

.ps-container:hover>.ps-scrollbar-y-rail:hover>.ps-scrollbar-y {
    background-color: #d9042b
}