#header {
	cursor: default;
	z-index: 99;
	height: 50px;
	width: 100%;
	background-color: $color-grey-dark;
	color: $color-white;
	position: fixed;
	top: 0;
	border-bottom: 1px solid $border-color;
	.logo {
		height: 50px;
		width: 210px;
		border-bottom: 1px solid $border-color;
		@include transition(all, 0.3s, ease-in-out);
		&:hover {
			cursor: pointer;
			background-color: $color-blue-dark;
			border-bottom: 1px solid $border-color;
		}
	}
	.nav-menu {
		font-family: $font2, sans-serif;
		font-weight: 400;
		float: right;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			a {
				font-size: 12px;
				display: inline-block;
				height: 50px;
				padding: 0 20px;
				text-transform: uppercase;
				color: $color-white;
				text-decoration: none;
				border-bottom: 1px solid $border-color;
				@include transition(all, 0.3s, ease-in-out);
				img {
					display: inline-block;
					line-height: 50px;
					height: 50px;
					width: 50px;
					float: left;
					margin-right: 15px;
					border-bottom: 1px solid $border-color;
				}
				li {
					display: inline-block;
					line-height: 50px;
					height: 100%;
					float: left;
				}
				&:hover {
					background-color: $color-blue-dark;
					border-bottom: 1px solid $border-color;
				}
				&.red {
					background-color: $color-red;
					padding: 0 15px;
					border-bottom: $border-color;
					&:hover {
						background-color: $color-red-dark;
					}
					&.loggedin {
						padding-left: 2px;

					}
				}
			}
		}
		.dropdown {
			display: none;
			position: absolute;
			top: 55px;
			right: 0;
			z-index: 89;
			width: 220px;
			background-color: $color-grey-dark;
			border: 1px solid $border-color;
			ul {
				a {
					font-size: 12px;
					display: block;
					height: 50px;
					padding: 0 20px;
					text-transform: uppercase;
					color: $color-white;
					text-decoration: none;
					border: none;
					@include transition(all, 0.3s, ease-in-out);
					&:hover {
						background-color: $color-blue-dark;
						border: none;
					}
					li {
						display: inline-block;
						line-height: 50px;
						height: 100%;
						float: left;
					}
				}
			}
		}
		&.menu {
			position: absolute;
			top: 50px;
			left: 0;
			right: 0;
			background-color: $color-grey-dark;
			border-bottom: 1px solid $border-color;
			ul {
				a {
					border-bottom: none;
					display: block;
				}
				hr {
					border: 0;
					height: 1px;
					width: 100%;
					color: $border-color;
					background-color: $border-color;
				}
			}

		}
	}
	.menu-btn {
		line-height: 50px;
		float: right;
		font-size: 12px;
		display: inline-block;
		height: 50px;
		padding: 0 20px;
		padding-top: 5px;
		text-transform: uppercase;
		color: $color-white;
		text-decoration: none;
		background-color: $color-red;
		border-bottom: 1px solid $border-color;
		@include transition(all, 0.3s, ease-in-out);
		&:hover {
			cursor: pointer;
			background-color: $color-red-dark;
		}
	}
}
