* { box-sizing: border-box; }

body {
	background-image: url('../img/bg.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	font-family: 'Gotham Book', sans-serif;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

pre {
	background-color: rgba(0, 0, 0, 0.2);
	margin: 0;
	color: $color-blue-light;
	padding: 5px 10px;
	border-radius: 3px;
	display: inline-block;
	&.margin {
		margin: 3px 0;
	}
}

#pup {
  position:absolute;
  z-index:200; /* aaaalways on top*/
  padding: 3px;
  margin-left: 10px;
  margin-top: 5px;
  width: 200px;
  border: 1px solid black;
  background-color: rgba(0,0,0,0.75);
  color: $color-white;
  font-size: 12px;
}

.container {
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	color: $color-white;
	.container-wrap {
		width: 100%;
		padding: 25px;
		.page {
			padding: 25px;
			width: 960px;
			border-radius: 5px;
			background-color: rgba(0, 0, 0, 0.4);
			margin: 0 auto;
			@media screen and (max-width: 960px) {
				width: 100%;
			}
			table {
				width: 100%;
				text-align: center;
				border-radius: 5px;
				tr {
					&:nth-child(odd) {
						background-color: rgba(0,0,0,0.7);
					}
					&:nth-child(even) {
						background-color: rgba(0,0,0,0.3);
					}
					td {
						h6 {
							font-size: 14px;
							margin: 5px 0;
						}
					}
					th {
						h3 {
							color: $color-red;
						}
					}
				}
			}
			h2 {
				padding: 0;
				text-transform: uppercase;
				font-family: $font2, sans-serif;
				margin: 0 0 25px 0;
				font-size: 30px;
			}
			h3 {
				text-transform: uppercase;
			}
			p {
				font-size: 13px;
			}
			span {
				font-size: 13px;
			}
			a {
				color: $color-yellow;
				text-decoration: none;
				@include transition(all, 0.3s, ease-in-out);
				&:hover {
					color: $color-yellow-dark;
				}
			}
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
			}
			.box {
				padding: 25px 0;
			}
			.info {
				font-family: $font1, sans-serif;
				color: $color-yellow;
			}
			.warning {
				font-family: $font1, sans-serif;
				color: $color-red;
			}
			.success {
				font-family: $font1, sans-serif;
				color: $color-green;
			}
			input[type="text"] {
				background-color: rgba(0,0,0,0);
				border-radius: 3px;
				padding: 7px 15px;
				color: $color-white;
				font-family: $font2, sans-serif;
				border: 2px solid $color-yellow-dark;
				display: block;
				width: 100%;
				margin-bottom: 20px;
				@include transition(all, 0.3s, ease-in-out);
				&:focus {
					border: 2px solid $color-yellow;
				}
			}
			input[type="submit"] {
				background-color: rgba(0,0,0,0);
				border-radius: 3px;
				padding: 15px 30px;
				color: $color-white;
				text-transform: uppercase;
				font-family: $font2, sans-serif;
				border: 2px solid $color-green;
				display: block;
				@include transition(all, 0.3s, ease-in-out);
				&:hover {
					background-color: rgba(4, 217, 57, 0.1);
					cursor: pointer;
				}
			}
		}
	}
}

.half-width {
	width: 50%;
}
.overlay {
	display: none;
	cursor: default;
	background-color: rgba(0, 0, 0, 0.9);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 59;
	.overlay-wrap {
		vertical-align: middle;
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		text-align: center;
		padding: 25px 10px;
		text-transform: uppercase;
		color: $color-white;
		h2 {
			font-family: $font2, sans-serif;
			font-size: 30px;
			padding: 0;
			margin: 0;
		}
		.content {
			margin: 20px auto;
			p {
				font-size: 11px;
			}
		}
		.buttons {
			a {
				&.button {
					display: inline-block;
					border-radius: 3px;
					padding: 15px 30px;
					color: $color-white;
					text-transform: uppercase;
					font-family: $font2, sans-serif;
					&:nth-child(2) {
						margin-left: 15px;
					}
					&:hover {
						cursor: pointer;
					}
					&.yes {
						border: 2px solid $color-green;
						@include transition(all, 0.3s, ease-in-out);
						&:hover {
							background-color: rgba(4, 217, 57, 0.1);
						}
					}
					&.no {
						border: 2px solid $color-red;
						@include transition(all, 0.3s, ease-in-out);
						&:hover {
							background-color: rgba(217, 4, 43, 0.1);
						}
					}
				}
			}
		}
	}
}

#content {
	cursor: default;
	section {
		float: left;
		// background-color: $color-blue-dark2;
		&:nth-child(1) {
			position: relative;
			width: 75%;
			div {
				float: left;
				&#log {
					overflow: hidden;
					position: relative;
					width: 75%;
                    .log-wrap {
						margin: 0 auto;
						text-align: center;
						width: 100%;
						.round {
							width: 100%;
							padding: 20px;
							header {
								background-color: rgba(0, 0, 0, 0.4);
								border-top: 1px solid rgba(0, 0, 0, 0.4);
								border-left: 1px solid rgba(0, 0, 0, 0.4);
								border-right: 1px solid rgba(0, 0, 0, 0.4);
								padding: 10px;
								border-top-left-radius: 3px;
								border-top-right-radius: 3px;
								h3 {
									display: block;
									text-align: center;
									text-transform: uppercase;
									color: $color-grey-light;
									font-size: 11px;
									margin: 5px 0 0 0;
									padding: 0;
									font-family: $font1;
									font-weight: normal;
									&:first-child {
										margin: 0;
									}
									span {
										color: $color-red;
										&:first-child {
											margin-right: 10px;
										}
									}
								}
								h2 {
								margin: 3px;
								padding: 0;
								font-family: $font2;
									a {
										color: $color-yellow;
										text-decoration: none;
										@include transition(all, 0.3s, ease-in-out);
										&:hover {
											color: $color-yellow-dark;
										}
									}
								}
							}
							ul {
								margin: 0;
								padding: 0;
								border-top: 1px solid rgba(0, 0, 0, 0.4);
								border-left: 1px solid rgba(0, 0, 0, 0.4);
								border-right: 1px solid rgba(0, 0, 0, 0.4);
								font-family: $font1, sans-serif;
								font-size: 11px;
								text-align: left;
								width: 100%;
								list-style: none;
								color: $color-grey-light;
								background-color: rgba(0, 0, 0, 0.2);
								li {
									display: block;
									padding: 10px;
									width: 100%;
									border-bottom: 1px solid rgba(0, 0, 0, 0.4);
									a {
										color: $color-green;
										text-decoration: none;
										@include transition(all, 0.3s, ease-in-out);
										&:hover {
											color: $color-green-dark;
										}
									}
								}
							}
							footer {
								background-color: rgba(0, 0, 0, 0.4);
								border-bottom: 1px solid rgba(0, 0, 0, 0.4);
								border-left: 1px solid rgba(0, 0, 0, 0.4);
								border-right: 1px solid rgba(0, 0, 0, 0.4);
								border-bottom-left-radius: 3px;
								border-bottom-right-radius: 3px;
								padding: 10px;
								h3 {
									display: block;
									text-align: right;
									text-transform: uppercase;
									color: $color-grey-light;
									font-size: 11px;
									margin: 0;
									padding: 0;
									font-family: $font1;
									font-weight: normal;
									span {
										color: $color-red;
									}
								}
							}
						}
                    }
				}
				&#items {
					border-left: 1px solid $border-color;
					overflow: hidden;
					position: relative;
					text-align: center;
					.items-wrap {
						padding-right: 10px;
						display: block;
						margin: 10px auto;
						padding: 0;
						.item {
							border: 1px solid rgba(0, 0, 0, 0.4);
							background-color: rgba(0, 0, 0, 0.1);
							position: relative;
							background-repeat: no-repeat;
							background-position: center center;
							background-size: cover;
							width: 80px;
							height: 80px;
							display: inline-block;
							list-style-type: none;
							margin: 5px;
							border-radius: 3px;
							@include transition(all, 0.3s, ease-in-out);
							.price {
								position: absolute;
								display: block;
								left: 0;
								bottom: 0;
								width: 100%;
								background-color: rgba(0, 0, 0, 0.4);
								font-size: 10px;
								color: $color-red;
								text-transform: uppercase;
								text-align: center;
								padding: 5px 0;
								border-bottom-left-radius: 3px;
								border-bottom-right-radius: 3px;
							}
							&:hover {
								cursor: pointer;
								border: 1px solid $color-red;
							}
						}
					}
				}
				&#potinfo {
					width: 100%;
					border-top: 1px solid $border-color;
					height: 150px;
					.info {
						border-right: 1px solid $border-color;
						height: 150px;
						width: 286px;
						display: inline-block;
						.row {
							height: 75px;
							width: 100%;
							display: block;
							.col {
								padding-top: 15px;
								text-align: center;
								float: left;
								height: 75px;
								width: 95px;
								h4 {
									margin: 0;
									font-size: 20px;
									color: $color-red;
									font-family: $font2, sans-serif; }
								span {
									color: $color-grey-light;
									font-size: 11px;
									text-transform: uppercase;
								}
							}
						}
					}
					.progress {
						display: inline-block;
						height: 150px;
						.line {
							padding: 0 40px;
							margin-top: 15px;
							outline: 0px solid white;
							width: 100%;
							.blue-line {
								width: 100%;
								height: 25px;
								border-radius: 3px;
								background-color: #131E23;
								border: 1px solid $border-color;
								position: relative;
								float: left;

								.red-line {
									position: absolute;
									top: 0;
									bottom: 0;
									background-color: $color-red;
									width: 66%;
									border-radius: 3px;
									span {
										text-align: center;
										color: $color-white;
										width: 100%;
										display: block;
										line-height: 25px;
										height: 25px;
										font-family: $font2, sans-serif;
										font-size: 13px;
									}
								}
							}
						}
						.timer {
							text-align: center;
							width: 100%;
							margin-top: 15px;
							h4 {
								margin: 0;
								font-size: 20px;
								color: $color-red;
								font-family: $font2, sans-serif;
							}
							span {
								color: $color-grey-light;
								font-size: 11px;
								text-transform: uppercase;
							}
						}
						a {
							&.button {
								background-color: $color-red;
								position: absolute;
								bottom: 15px;
								right: 15px;
								font-size: 12px;
								display: inline-block;
								height: 50px;
								line-height: 50px;
								padding: 0 25px;
								text-transform: uppercase;
								color: $color-white;
								text-decoration: none;
								@include transition(all, 0.3s, ease-in-out);
								&:hover {
									cursor: pointer;
									background-color: $color-red-dark;
								}
							}
						}
					}
				}
			}
		}
		&:nth-child(2) {
			position: relative;
			width: 25%;
			border-left: 1px solid $border-color;
			#chat {
				width: 100%;
				height: 100%;
				position: absolute;
				display: flex;
				flex-direction: column;
				#messages {
					flex: 1;
					display: flex;
					flex-direction: column;
					position: relative;
					.messages-wrap {
						.message {
							padding: 0;
							margin: 0;
							min-height: 60px;
							@include transition(all, 0.3s, ease-in-out);
							&:nth-child(odd) {
								background-color: rgba(0, 0, 0, 0.1);
							}
							.avatar {
								float: left;
								opacity: 0.7;
								border: 1px solid #15161D;
								border-radius: 3px;
								background: center center no-repeat;
								background-size: cover;
								height: 50px;
								width: 50px;
								display: inline-block;
								margin: 5px;
							}
							.user {
								opacity: 0.7;
								width: calc(100% - 60px);
								padding: 5px;
								display:
								inline-block;
								min-height: 60px;
								.name {
									font-size: 12px;
									font-family: $font2, sans-serif;
									color: $color-red-dark;
									text-decoration: none;
									@include transition(all, 0.3s, ease-in-out);
									&:hover {
										color: $color-red;
									}
									&.admin {
										color: $color-blue-light;
										&:hover {
											color: $color-blue-light;
										}
									}
								}
								.text {
									width: auto;
									font-size: 11px;
									color: $color-white;
									display: block;
									margin-top: 5px;
								}
							}
							&:hover {
								> .avatar {
									opacity: 0.95;
									@include transition(all, 0.3s, ease-in-out);
								}
								> .user {
									opacity: 0.95;
									@include transition(all, 0.3s, ease-in-out);
								}
							}
						}
					}
				}
				.input {
					display: block;
					width: 100%;
					height: 70px;
					textarea {
						width: 100%;
						height: 70px;
						border: none;
						border-top: 1px solid $border-color;
						color: $color-white;
						background-color: rgba(0,0,0,0);
						font-family: $font1, sans-serif;
						padding: 10px;
						font-size: 11px;
						resize: none;
						@include transition(all, 0.3s, ease-in-out);
						&:focus {
							border-bottom: 5px solid $color-red;
							outline: none;
						}
					}
				}
			}
		}
	}
}
