#footer {
    cursor: default;
    z-index: 99;
    height: 72px;
    width: 100%;
    background-color: $color-grey-dark;
    color: $color-white;
    position: fixed;
    bottom: 0;
    border-top: 1px solid $border-color;
    a {
        &.button { float: left;
            font-size: 12px;
            display: inline-block;
            height: 72px;
            line-height: 72px;
            padding: 0 25px;
            text-transform: uppercase;
            color: $color-white;
            text-decoration: none;
            @include transition(all, 0.3s, ease-in-out);
            &:hover {
                background-color: $color-blue-dark;
            }
            &.icon {
                padding: 0 10px;
                i {
                    line-height: inherit;
                }
            }
        }
    }
    div {
        &.info {
            display: inline-block;
            float:right;
            span {
                text-align: right;
                display: block;
                color: $color-grey-light;
                text-transform: uppercase;
                line-height: 20px;
                font-size: 9px;
                margin-right: 15px;
                &.copyright {
                    margin-top: 16px;
                }
                &.author {
                    margin-bottom: 16px;
                    a {
                        color: $color-red-dark;
                        text-decoration: none;
    					@include transition(all, 0.3s, ease-in-out);
                        &:hover {
                            color: $color-red;
                        }
                    }
                }
            }
        }
        &.info2 {
            display: inline-block;
            float: left;
            span {
                text-align: left;
                display: block;
                color: $color-grey-light;
                text-transform: uppercase;
                line-height: 20px;
                font-size: 9px;
                margin-left: 15px;
                &.line1 {
                    margin-top: 13px;
                }
                &.line2 {
                    margin-bottom: 16px;
                    font-size: 20px;
                }
            }
        }
    }
}
